import React from 'react'

const Footer = () => {
    return (
        <div>
            <footer>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img src="assets/quresoft_white.png" className="footer-logo" />
                        <br /><br /><br />

                        <a href="/" className="footer-links">Home</a>
                        <a href="#about" className="footer-links">About</a>
                        <a href="#our-products" className="footer-links">Our Products</a>
                        <a href="#contact-us" className="footer-links">Contact Us</a>
                        {/*<a href="" className="footer-links">FAQ</a> */}

                        <br /><br /><br />
                        <a href=""><img src="assets/images/icons/facebook.png" className="footer-icon" /></a>
                        <a href=""><img src="assets/images/icons/twitter.png" className="footer-icon" /></a>
                        <a href=""><img src="assets/images/icons/hash.png" className="footer-icon" /></a>
                        <a href=""><img src="assets/images/icons/github.png" className="footer-icon" /></a>

                    </div>
                </div>
            </footer>

            <div className="row">
                <div className="col-md-12 footer-bottom">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center">
                            <p>Copyright© <a href="https://eighty5technologies.com/home/">EightyFive Technologies</a> 2021. All rights reserved</p>
                        </div>
                        <div className="col-md-4 text-center">
                            <a href="" className="footer-bottom-link">Terms & Conditions</a>
                            <a href="" className="footer-bottom-link">Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
