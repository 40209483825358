import React from 'react';
import "./Navbar.css"

const Navbar = () => {
    return (
        <div className='navbar-container'>

            <div className="row">
                <div className="col-md-12 top-border"></div>
            </div>

            <nav className="navbar navbar-expand-lg navbar-light bg-light custom-nav">
                <a className="navbar-brand" href="#"><img src="assets/quresolf-logo.png" className="nav-logo" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav" style={{ display: "flex", flex:1, justifyContent: "space-evenly"}}>
                        <li className="nav-item active">
                            <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#about">About Us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#our-products">Our Products</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#contact-us">Contact Us</a>
                        </li>

                    </ul>
                    {/*} <form className="form-inline my-2 my-lg-0">
                        <button className="btn btn-outline-success my-2 my-sm-0 nav-sign-in" type="button">Sign In</button>
                        <button className="btn btn-outline-success my-2 my-sm-0 nav-log-in" type="button">Login</button>
    </form> */}
                </div>
            </nav>
        </div>
    )
}

export default Navbar
