import './App.css';
import Footer from './Footer';
import MainPage from './MainPage';
import Navbar from './Navbar';

function App() {
  return (
    <div>
      <Navbar />
      <MainPage />
      <Footer />
    </div>
  );
}

export default App;
