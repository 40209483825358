import React, { useEffect, useRef, useState } from 'react';
import "./MainPage.css";
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
const MainPage = () => {

    const contentContainerRef = useRef(null);
    const leftContentRef = useRef(null);
    const contentMarkerRef = useRef(null);
    const headerRef = useRef(null);

    const revealRefs = useRef([]);
    revealRefs.current = [];
    useEffect(() => {
        gsap.to(headerRef.current, { duration: 1, ease: 'none' });


    });
    useEffect(() => {
        gsap.from(headerRef.current, {
            autoAlpha: 0,
            ease: 'none',
            delay: 1
        });

        revealRefs.current.forEach((el, index) => {

            gsap.fromTo(el, {
                autoAlpha: 0
            }, {
                duration: 1,
                autoAlpha: 1,
                ease: 'none',
                scrollTrigger: {
                    id: `section-${index + 1}`,
                    trigger: el,
                    start: 'top center+=100',
                    toggleActions: 'play none none reverse'
                }
            });

        });

    }, []);
    const addToRefs = el => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };
    return (
        <div>
            <section id="who-we-are">

                <div className="row mb-5" id="about" >
                    <div className="col-md-12 ">

                        <h2 >ABOUT US</h2>
                        <h1>Who we are?</h1>

                        <p className="mt-4">Quresoft Technology Consulting Private Limited (Quresoft) is a start-up bringing in innovative technology based solutions to address the challenges of back-end healthcare services. It is an unlisted private organization incorporated as per company act on 3rd August 2021.</p>
                        <p> Introducing patients to digital technology based health-data solutions, Quresoft has the relevant expertise to design machine learning technologies in order to support treatment outcomes related to public health. </p><p> Lending Artificial Intelligence (AI) based expertise we have designed an effective healthcare technology platform to align specific patient related data, coupled with a complete automation of human task management. Encouraging open-ended collaboration between technical personnel, we build next generation solutions as far as healthcare technology services’ remain concerned.</p>

                        <button className="btn btn-primary learn-more mt-4">LEARN MORE</button>
                    </div>
                </div>


                

            </section>

            <section id="our-products">

                <div className="row">
                    <div className="col-md-12">

                        <h2>PLACERAT DIAM</h2>
                        <h1>Our Products</h1>
                        <p className="mt-4">QUREHEALTH: A UNIQUE HEALTH INFORMATION SYSTEM (UHIS).</p>

                        <p>Building on an exclusive healthcare technology platform, we bring, ‘paperless hospital services’ at the doorstep. A platform to organize ‘next generation data’ on healthcare requirements, quresoft plays an integral role in closing the communication gaps between the service providers and the patients.</p>
                        <p>Thus, quresoft platform remains to be a cycle of effective data administration and delivery framework to help patients maximize returns on healthcare investments. Simultaneously, offering integrated technology - based interaction, quresoft can help in improving treatment outcomes for various categories of patients. Floating large volumes of data, a major aspect of his is data security.</p>

                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <input type="text" name="" className="form-control topText" placeholder="Enter email address to receive a newsletter.." />
                        <button className="btn btn-primary submit-btn">Submit</button>
                    </div>
                    <div className="col-md-3"></div>
                </div>
                <br />
            </section>
            <section>
                <div class="content-container">
                    <div class="row">
                        <div class="col-md-12">
                            {/*First slider */}
                            <div className="row" key="1" ref={addToRefs}>
                                <div className="col-md-4 left-content">
                                    <img id="img1" src="assets/images/background/product-mobile.png" alt="product" />
                                </div>
                                <div className="col-md-8 right-content">
                                    <p>
                                        <img src="assets/images/icons/hospital-icon.png" className="product-icon" />
                                        <h2>Hospital Management System (HMS)</h2>

                                        <p>Hospital management system is an automated ‘care delivery model’ in order to increase consumer satisfaction in the context of a complicated treatment process.
                                        </p>

                                        <p> Driving satisfactory treatment outcomes, HMS drive a successful communication model, to bridge the gap between a healthcare service provider and a patient.
                                        </p>

                                        <br />
                                        <br />
                                        <a href="">Learn More</a>
                                        <br />  </p>
                                </div>
                            </div>

                            {/* Second slider */}

                            <div className="row top-f" key="2" ref={addToRefs}>
                                <div className="col-md-4 left-content">
                                    <img id="img1" src="assets/images/background/product-mobile.png" alt="product" />
                                </div>
                                <div className="col-md-8 right-content">
                                    <p>
                                        <img src="assets/images/icons/analytical-icon.png" className="product-icon" />
                                        <h2>Appointment Management</h2>
                                        <p>Commonly known as 'online appointment scheduling system', Quresoft brings in complete patient convenience built-on technology.
                                        </p>

                                        <p> Bridging the gap between a service provider and patient scheduling mechanism, the technology is built-in to promote absolute user convenience. Automated text messages and emails are sent to patients well in advance.

                                        </p>
                                        <p>Pharmacy management system: A complete medical store management system brought in by Quresoft on a virtual platform. As a patient, you can take a quick stock of medicines available and order as per requirements. Ordering your medicines from the online pharmacy can help you with a ‘doorstep delivery’mechanism precisely.</p>
                                        <p>Medical Records Management system (MRD) : A virtual system of documentation developed by Quresoft, helping patients, hospitals and other players to manage medical documents effectively. Helping the patients to store volumes of medical records, you can refer them handy while taking ‘virtual appointments’.
                                        </p>

                                        <br />
                                        <br />
                                        <a href="">Learn More</a>
                                        <br /> </p>
                                </div>
                            </div>

                            {/*Third Slider */}
                            <div className="row top-f" key="3" ref={addToRefs}>
                                <div className="col-md-4 left-content">
                                    <img id="img1" src="assets/images/background/product-mobile.png" alt="product" />
                                </div>
                                <div className="col-md-8 right-content">
                                    <p>
                                        <img src="assets/images/icons/advanced-icon.png" className="product-icon" />
                                        <h2>Healthcare HR module (Payroll, attendance etc) - Light weight</h2>
                                        <p>An advanced software application consolidating the HR functions singularly.
                                        </p>

                                        <p>Designed by team Quresoft, the Healthcare HR module promotes a complete engagement of the healthcare service providers, tracking of job roles and employment history coupled with displays of ready reckoners of employee performance.
                                        </p>

                                        <br />
                                        <br />
                                        <a href="">Learn More</a>
                                        <br /> </p>
                                </div>
                            </div>

                            {/*Fourth Slider*/}
                            <div className="row top-f" key="4" ref={addToRefs}>
                                <div className="col-md-4 left-content">
                                    <img id="img1" src="assets/images/background/product-mobile.png" alt="product" />
                                </div>
                                <div className="col-md-8 right-content">
                                    <p>
                                        <img src="assets/images/icons/analytical-icon.png" className="product-icon" />
                                        <h2>Mobile app (to track appointments, approvals and reports:</h2>
                                        <p>Designed to run on a mobile platform, along with a tablet, the Mobile software application developed by us can help patients to track doctor appointments or download reports.
                                        </p>



                                        <br />
                                        <br />
                                        <a href="">Learn More</a>
                                        <br />  </p>
                                </div>
                            </div>

                            {/*Fifth Slider*/}
                            <div className="row top-f" key="5" ref={addToRefs}>
                                <div className="col-md-4 left-content">
                                    <img id="img1" src="assets/images/background/product-mobile.png" alt="product" />
                                </div>
                                <div className="col-md-8 right-content">
                                    <p>
                                        <img src="assets/images/icons/analytical-icon.png" className="product-icon" />
                                        <h2>Clinical Analytics Consultation (clinical dashboards, improvement recommendations etc)
                                        </h2>
                                        <p>Clinical Analytics help the doctors to analyse medical reports using ‘real time medical data ’. Clinical Analytics Consultation services help the doctors to reach a meaningful conclusion in relation to a patient suffering from chronic illness.    </p>



                                        <br />
                                        <br />
                                        <a href="">Learn More</a>
                                        <br /> </p>
                                </div>
                            </div>


                            {/*Sixth Slider*/}
                            <div className="row top-f" key="6" ref={addToRefs}>
                                <div className="col-md-4 left-content">
                                    <img id="img1" src="assets/images/background/product-mobile.png" alt="product" />
                                </div>
                                <div className="col-md-8 right-content">
                                    <p>
                                        <img src="assets/images/icons/analytical-icon.png" className="product-icon" />
                                        <h2>Hospital Management Consultancy services (helps in due diligence of setting up hospitals, cost estimation and best practices)
                                        </h2>
                                        <p>Quresoft helps investors in understanding the feasibility of a business proposal related to setting up of hospitals.</p>
                                        <p>Assessment of the practicality of the project can be determined through an objective oriented market study undertaken through a qualified team in Quresoft. Within the defined scope of work, expert research professionals associated with Quresoft undertake a detail oriented project report to be presented to the investors and financial institutions providing bank loan. On behalf of the clients, we also undertake competitor analysis of a healthcare brand especially, while helping clients to reposition the brand in a competitive market.
                                        </p>


                                        <br />
                                        <br />
                                        <a href="">Learn More</a>
                                        <br /> </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section id="our-products">

                <div className="row">
                    <div className="col-md-12">

                        <h2></h2>
                        <h1>Our Services</h1>
                        <p className="mt-4">The global healthcare segment had evolved largely in the recent past. Keeping in view of upcoming challenges in an evolving market, Quresoft had come up with effective technology solutions helping the stalwart healthcare brands to reach out to the consumers.</p>

                        <p>Understanding the rising requirements for virtual patient care, we have successfully built up an interactive technology - based platform encouraging automation of manual tasks. </p>
                        <p>Improving back – end workflows on a large scale, Quresoft had focused on building value-based health data management systems to improve treatment outcomes. Engaging stakeholders in the healthcare segment, to serve the patients better, we create innovative consumer experiences in the given context. </p>

                    </div>
                </div>
                <br />
            </section>
            <section id="our-vision">

                <div className="row">
                    <div className="col-md-6">
                        <h2>Our Vision</h2>

                        <p className="mt-4">Our Vision is to bring in a complete digital transformation to the concept of healthcare technology platform in the global context.</p>
                        <p>Simultaneously, Quresoft promotes preventive care and management to define overall well-being of the patients.</p><p> Bringing in a connected web of major stakeholders through a cloud based platform we provide an intense care mechanism to influence health outcomes of millions.</p>
                    </div>
                    <div className="col-md-6 mt-5">
                        <img src="assets/images/background/our-vision-img.png" className="our-vision-img" />
                    </div>
                </div>

            </section>

            <section id="project-overview">

                <div className="row">
                    <div className="col-md-12">

                        <h2>PLACERAT DIAM</h2>
                        <h1>Project Overview</h1>
                        <p className="mt-4">Aenean et neque eleifend, dictum sem a, placerat diam.Phasellus congue pharetra<br className="d-none d-md-block" /> dui, eget sagittis ipsum pellentesque ut.</p>

                    </div>
                </div>

            </section>

            <section id="project-overview-slider">

                <div className="row">
                    <div className="col-md-12">

                        <button className="my-previous-button"><img src="assets/images/icons/prev.png" className="btn_prev_img" /></button>
                        <button className="my-next-button"><img src="assets/images/icons/next.png" className="btn_next_img" /></button>


                        <div className="owl-carousel owl-theme" id="overview-slider">


                            <div className="item">
                                <img src="assets/images/background/prod-img-1.png" className="project-slider-img" />
                                <h2>Phasellus congue<br />phare eleifend</h2>
                                <p className="mt-4">Aenean et neque eleifend, dictum se
                                    a, placerat diam. Phasellus congue
                                    pharetra dui,eget sagittis ipsum pel
                                    lentesque ut.  </p>
                                <p>
                                    Phasellus bibendum velit eget dui
                                    sagittis, id consequat elit posuere.
                                    Vivamus tempor purus nibh, eu cong
                                    ante sagittis id.Phasellus congue
                                    pharetra dui,eget sagittis ipsum
                                    pellentesque ut.  </p>
                            </div>


                            <div className="item">
                                <img src="assets/images/background/prod-img-2.png" className="project-slider-img" />
                                <h2>Phasellus congue<br />phare eleifend</h2>
                                <p className="mt-4">Aenean et neque eleifend, dictum se
                                    a, placerat diam. Phasellus congue
                                    pharetra dui,eget sagittis ipsum pel
                                    lentesque ut.  </p>
                                <p>
                                    Phasellus bibendum velit eget dui
                                    sagittis, id consequat elit posuere.
                                    Vivamus tempor purus nibh, eu cong
                                    ante sagittis id.Phasellus congue
                                    pharetra dui,eget sagittis ipsum
                                    pellentesque ut.  </p>
                            </div>

                            <div className="item">
                                <img src="assets/images/background/prod-img-3.png" className="project-slider-img" />
                                <h2>Phasellus congue<br />phare eleifend</h2>
                                <p className="mt-4">Aenean et neque eleifend, dictum se
                                    a, placerat diam. Phasellus congue
                                    pharetra dui,eget sagittis ipsum pel
                                    lentesque ut.  </p>
                                <p>
                                    Phasellus bibendum velit eget dui
                                    sagittis, id consequat elit posuere.
                                    Vivamus tempor purus nibh, eu cong
                                    ante sagittis id.Phasellus congue
                                    pharetra dui,eget sagittis ipsum
                                    pellentesque ut.  </p>
                            </div>

                            <div className="item">
                                <img src="assets/images/background/prod-img-1.png" className="project-slider-img" />
                                <h2>Phasellus congue<br />phare eleifend</h2>
                                <p className="mt-4">Aenean et neque eleifend, dictum se
                                    a, placerat diam. Phasellus congue
                                    pharetra dui,eget sagittis ipsum pel
                                    lentesque ut.  </p>
                                <p>
                                    Phasellus bibendum velit eget dui
                                    sagittis, id consequat elit posuere.
                                    Vivamus tempor purus nibh, eu cong
                                    ante sagittis id.Phasellus congue
                                    pharetra dui,eget sagittis ipsum
                                    pellentesque ut.  </p>
                            </div>

                            <div className="item">
                                <img src="assets/images/background/prod-img-2.png" className="project-slider-img" />
                                <h2>Phasellus congue<br />phare eleifend</h2>
                                <p className="mt-4">Aenean et neque eleifend, dictum se
                                    a, placerat diam. Phasellus congue
                                    pharetra dui,eget sagittis ipsum pel
                                    lentesque ut.  </p>
                                <p>
                                    Phasellus bibendum velit eget dui
                                    sagittis, id consequat elit posuere.
                                    Vivamus tempor purus nibh, eu cong
                                    ante sagittis id.Phasellus congue
                                    pharetra dui,eget sagittis ipsum
                                    pellentesque ut.  </p>
                            </div>


                        </div>

                    </div>
                </div>

            </section>

            <section id="contact-us">
                <div className="row">
                    <div className="col-md-12">
                        <h2>Contact Us</h2>
                    </div>
                </div>
                <div className="white-line"></div>
                <br />
                <br />
                <div className="row mt-5">
                    <div className="col-md-4">
                        <a href="tel:+91 5362745298">+91 5362745298</a>
                        <p>Office Number</p>
                    </div>
                    <div className="col-md-4">
                        <a href="tel:+91 5362745298">+91 5362745298</a>
                        <p>What's App Number</p>
                    </div>
                    <div className="col-md-4">
                        <a href="mailto:info@quresoft.com">info@quresoft.com</a>
                        <p>Our Email</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MainPage
